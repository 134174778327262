import { z } from "zod";

import {
  SmartActionTemplateEventSchema,
  SmartActionTemplateSchema,
  SmartActionTemplateWhenSchema,
} from "@lib/data/schemas/smart-action-template";

import { SchemaProperties } from "../repositories/schema-properties-interface";

import { BaseSchema } from "./base-schema";

export const PackageStatusSchema = z.enum(["active", "inactive", "deleted"]);

export type PackageStatusType = z.infer<typeof PackageStatusSchema>;

export const PackageContentTypeSchema = z.enum(["sessions", "time"]);
export type PackageContentType = z.infer<typeof PackageContentTypeSchema>;

export const PackageTimeTypeSchema = z.enum(["minutes", "hours"]);
export type PackageTimeType = z.infer<typeof PackageTimeTypeSchema>;

export const PackageFrequencyTypeSchema = z.enum(["months", "weeks"]);
export type PackageFrequencyTypeType = z.infer<
  typeof PackageFrequencyTypeSchema
>;

export const PackageFrequencySchema = z.object({
  total: z.number(),
  type: PackageFrequencyTypeSchema,
});
export type PackageFrequencyType = z.infer<typeof PackageFrequencySchema>;

export const UsagePackageInvoiceCutOffDateSchema = z.enum([
  // monthly
  "first-of-month",
  "last-of-month",

  // weekly
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
]);

export type UsagePackageInvoiceCutOffDateType = z.infer<
  typeof UsagePackageInvoiceCutOffDateSchema
>;

export const UsagePackageInvoicingSchema = z.object({
  method: z.enum(["auto", "manual"]),
  period: z.enum(["months", "weeks"]),
  autoChargeDay: z.number().optional(),
  cutOffDate: UsagePackageInvoiceCutOffDateSchema.optional(),
  dueAfterDays: z.number().optional(),
});
export type UsagePackageInvoicingType = z.infer<
  typeof UsagePackageInvoicingSchema
>;

export const UsagePackagePricingSchema = z.object({
  amount: z.number(),
  currency: z.string(),
  taxTypeId: z.string(),
});
export type UsagePackagePricingType = z.infer<typeof UsagePackagePricingSchema>;

const PackageItemSchema = z.object({
  productId: z.string().optional(),
  schedulerId: z.string(),
  quantity: z.number(),
  order: z.number().optional(),
  usagePricing: UsagePackagePricingSchema.optional(),
  suggestedFrequency: PackageFrequencySchema.optional(),
  billingRateId: z.string().optional(),
});

const PackagePaymentTypesSchema = z.enum(["one-time", "split-payments"]);

const PackagePaymentSplitPeriodSchema = z.enum(["months", "weeks"]);

export const PackagePaymentSplitSchema = z.object({
  unit: z.string(),
  amount: z.number(),
  itemAmount: z.number().optional(),
  total: z.number(),
  dueOn: z.number(),
  period: PackagePaymentSplitPeriodSchema,
});

export type PackagePaymentSplitType = z.infer<typeof PackagePaymentSplitSchema>;

export const PackagePaymentOptionSchema = z.object({
  type: PackagePaymentTypesSchema,
  amount: z.number(),
  currency: z.string(),
  taxTypeId: z.string(),
  splits: PackagePaymentSplitSchema.array(),
});

export type PackagePaymentOptionType = z.infer<
  typeof PackagePaymentOptionSchema
>;

export const PackageTypeSchema = z.enum([
  "recurring",
  "one-time",
  "flexible",
  "usage",
]);
export type PackageTypeType = z.infer<typeof PackageTypeSchema>;

export const PackageBillingRateTypeSchema = z.enum(["multi", "single"]);
export type PackageBillingRateType = z.infer<
  typeof PackageBillingRateTypeSchema
>;

// @TODO: implement the rest of fields
export const PackageSchema = BaseSchema.extend({
  id: z.string().optional(),
  title: z.string(),
  slug: z.string(),
  description: z.string(),
  icon: z.string(),
  smartActionTemplates: SmartActionTemplateSchema.array().optional(),
  items: PackageItemSchema.array(),
  paymentOptions: PackagePaymentOptionSchema.array(),
  status: PackageStatusSchema,
  secret: z.boolean().optional(),
  publicOrder: z.number().optional().nullable(),
  distributeSessions: z.boolean().optional(),
  totalSessions: z.number().nullable().optional(),
  contentType: PackageContentTypeSchema.optional(),
  timeType: PackageTimeTypeSchema.nullable().optional(),
  autocomplete: z.boolean().optional(),
  packageType: PackageTypeSchema.optional(),
  frequency: PackageFrequencySchema.nullable().optional(),
  rollOver: z.boolean().optional(),
  billingRateId: z.string().nullable().optional(),
  packageBillingRateType: PackageBillingRateTypeSchema.optional().nullable(),
  acceptedOutcomes: z.array(z.string()).nullable().optional(),
  autoCancelOutcome: z.boolean().optional().nullable(),
  usageInvoicing: UsagePackageInvoicingSchema.optional().nullable(),
  allowFutureBooking: z.boolean().optional(),
  locked: z.boolean().optional(),
});

export type PackageType = z.infer<typeof PackageSchema>;
export type PackageItemsType = NonNullable<PackageType["items"]>[0];

// used in to type the form data
export const PackageFormDataSchema = PackageSchema.omit({
  id: true,
  status: true,
  smartActionTemplates: true,
  createdAt: true,
  updatedAt: true,
}).extend({
  smartActions: SmartActionTemplateSchema.extend({
    event: SmartActionTemplateEventSchema.omit({
      type: true,
    }).extend({
      when: SmartActionTemplateWhenSchema.extend({
        value: z.number().or(z.string()).optional(),
      }).optional(),
    }),
  })
    .array()
    .optional(),
  items: PackageItemSchema.extend({ quantity: z.string() }).array(),
  paymentOptions: PackagePaymentOptionSchema.extend({
    amount: z.string(),
    splits: PackagePaymentSplitSchema.extend({
      amount: z.number().or(z.string()).optional(),
    })
      .omit({
        total: true,
      })
      .array()
      .optional(),
  }).array(),
  usageInvoicing: UsagePackageInvoicingSchema.extend({
    autoChargeDay: z.number().or(z.string()).optional(),
  })
    .optional()
    .nullable(),
});

export type FormDataOptionType = PackageFormDataType["paymentOptions"][0] & {
  items: PackageItemsType[];
  usageInvoicing: UsagePackageInvoicingType;
};

export type FormDataSplitType = NonNullable<FormDataOptionType["splits"]>[0] & {
  total?: number;
};

export type PackageFormDataType = z.infer<typeof PackageFormDataSchema>;
export type PackageFormItemsType = NonNullable<PackageFormDataType["items"]>[0];

export const schemaProperties: SchemaProperties = {
  collectionName: "packages",
  deleteMode: "soft",
};
